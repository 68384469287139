import LandingPageContent from '../LandingPage/LandingPageContent';
import SEO, { Hreflang } from '../SEO/SEO';
import FrontPageCoverContainer from './FrontPageCover/FrontPageCoverContainer';
import useGetFrontPageData from './hooks/useGetFrontPageData';

const FrontPageContainer = () => {
  const { content: frontpageContent } = useGetFrontPageData();
  const seo = frontpageContent?.seo;

  const content = frontpageContent?.content;

  const hreflangs = (seo?._allCanonicalUrlLocales
    ?.filter((hreflang) => hreflang.locale && hreflang.value)
    .map((hreflang) => ({
      locale: hreflang.locale,
      value: hreflang.value,
    })) || []) as Hreflang[];

  return (
    <>
      <SEO
        canonicalUrl={seo?.canonicalUrl}
        description={seo?.description?.value}
        hreflangs={hreflangs}
        isIndexed
        openGraphImage={seo?.openGraphImage}
        title={seo?.title?.value}
      />
      <FrontPageCoverContainer />
      <LandingPageContent content={content} />
    </>
  );
};

export default FrontPageContainer;
