import styled from '@emotion/styled';
import { ImageFragment } from '@codegen/cmsUtils';
import { getImageSizes } from '@ui/utils/getImageSizes';
import Box from '@ui-v2/core/Box/Box';
import Image from '@ui-v2/core/Image/Image';
import SearchWidgetErrorBoundary from '../Search/SearchWidgetErrorBoundary';
import SearchWidgetWithProvider from '../SearchWidget/SearchWidget';

const StyledStaticImage = styled(Image)`
  width: 100%;
  height: auto;
`;

const LandingPageCoverContainer = ({
  children,
  defaultDestinations,
  defaultOrigins,
  image,
  shouldUseLocalStorage,
  useFloatingSearchWidget,
}: {
  children?: React.ReactNode;
  defaultDestinations?: string[];
  defaultOrigins?: string[];
  image?: ImageFragment | null;
  shouldUseLocalStorage: boolean;
  useFloatingSearchWidget?: boolean;
}) => {
  const fixedImageSize = Boolean(
    image?.width && image.height && image.width / image.height > 3,
  );

  return (
    <SearchWidgetErrorBoundary>
      <Box position="relative">
        {useFloatingSearchWidget && (
          <>
            <Box pt={[0, 0, 0, 64]} />
            <Box pt={[0, 0, 56, 80]} />
          </>
        )}
        {image?.url && (
          <Box
            bottom={0}
            left={0}
            position={useFloatingSearchWidget ? 'absolute' : 'static'}
            right={0}
            top={0}
          >
            {useFloatingSearchWidget ? (
              <Image
                alt={image.alt ?? ''}
                fill
                objectFit="cover"
                priority
                quality={75}
                sizes={
                  fixedImageSize
                    ? getImageSizes({ Default: '1920px' })
                    : undefined
                }
                src={image.url}
              />
            ) : (
              <Box display={['none', 'block', 'block']}>
                <StyledStaticImage
                  alt={image.alt ?? ''}
                  height={image.height}
                  priority
                  sizes={
                    fixedImageSize
                      ? getImageSizes({ Default: '1920px' })
                      : undefined
                  }
                  src={image.url}
                  width={image.width}
                />
              </Box>
            )}
          </Box>
        )}
        <Box position="relative" zIndex="2">
          {children}
          <Box
            bg={
              useFloatingSearchWidget
                ? 'transparent'
                : 'components.search.surface.default'
            }
            pb={[24, 24, useFloatingSearchWidget ? 48 : 24]}
            pt={[24, 24, useFloatingSearchWidget ? 72 : 24]}
            px={[16, 16, 24, 24, 24, 24, 0]}
            width="full"
          >
            <Box
              bg="surface.main"
              border="subdued"
              borderRadius="borderRadiusM"
              boxShadow="medium"
              maxWidth="container"
              mx="auto"
              p={16}
              width="full"
            >
              <SearchWidgetWithProvider
                config={{
                  shouldUseLocalStorage,
                  defaultOrigins,
                  defaultDestinations,
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </SearchWidgetErrorBoundary>
  );
};

export default LandingPageCoverContainer;
